// CLEAR_INSTANCE_DETAILS
export const CLEAR_INSTANCE_DETAILS = 'CLEAR_INSTANCE_DETAILS';

// TESTING
export const API_RESET_ALL_GEARS = 'API_RESET_ALL_GEARS';

export const API_GET_ALL_ORG_ROBOTS_REQUEST = 'API_GET_ALL_ORG_ROBOTS_REQUEST';
export const API_GET_ALL_ORG_ROBOTS_SUCCESS = 'API_GET_ALL_ORG_ROBOTS_SUCCESS';
export const API_GET_ALL_ORG_ROBOTS_FAILED = 'API_GET_ALL_ORG_ROBOTS_FAILED';

export const API_ONBOARD_ORG_ROBOT_REQUEST = 'API_ONBOARD_ORG_ROBOT_REQUEST';
export const API_ONBOARD_ORG_ROBOT_SUCCESS = 'API_ONBOARD_ORG_ROBOT_SUCCESS';
export const API_ONBOARD_ORG_ROBOT_FAILED = 'API_ONBOARD_ORG_ROBOT_FAILED';

export const API_UPDATE_ORG_ROBOT_REQUEST = 'API_UPDATE_ORG_ROBOT_REQUEST';
export const API_UPDATE_ORG_ROBOT_SUCCESS = 'API_UPDATE_ORG_ROBOT_SUCCESS';
export const API_UPDATE_ORG_ROBOT_FAILED = 'API_UPDATE_ORG_ROBOT_FAILED';

export const API_CREATE_SHOOT_ROBOT_REQUEST = 'API_CREATE_SHOOT_ROBOT_REQUEST';
export const API_CREATE_SHOOT_ROBOT_SUCCESS = 'API_CREATE_SHOOT_ROBOT_SUCCESS';
export const API_CREATE_SHOOT_ROBOT_FAILED = 'API_CREATE_SHOOT_ROBOT_FAILED';

export const API_GET_ALL_SHOOT_ROBOT_REQUEST = 'API_GET_ALL_SHOOT_ROBOT_REQUEST';
export const API_GET_ALL_SHOOT_ROBOT_SUCCESS = 'API_GET_ALL_SHOOT_ROBOT_SUCCESS';
export const API_GET_ALL_SHOOT_ROBOT_FAILED = 'API_GET_ALL_SHOOT_ROBOT_FAILED';

export const API_UPDATE_SHOOT_ROBOT_REQUEST = 'API_UPDATE_SHOOT_ROBOT_REQUEST'; // Update robot settings, operators, is_in_canvas
export const API_UPDATE_SHOOT_ROBOT_SUCCESS = 'API_UPDATE_SHOOT_ROBOT_SUCCESS';
export const API_UPDATE_SHOOT_ROBOT_FAILED = 'API_UPDATE_SHOOT_ROBOT_FAILED';

export const API_DELETE_SHOOT_ROBOT_REQUEST = 'API_DELETE_SHOOT_ROBOT_REQUEST';
export const API_DELETE_SHOOT_ROBOT_SUCCESS = 'API_DELETE_SHOOT_ROBOT_SUCCESS';
export const API_DELETE_SHOOT_ROBOT_FAILED = 'API_DELETE_SHOOT_ROBOT_FAILED';

export const API_CHECK_VALID_ROBOT_REQUEST = 'API_CHECK_VALID_ROBOT_REQUEST';
export const API_CHECK_VALID_ROBOT_SUCCESS = 'API_CHECK_VALID_ROBOT_SUCCESS';
export const API_CHECK_VALID_ROBOT_FAILED = 'API_CHECK_VALID_ROBOT_FAILED';
export const API_RESET_CHECK_VALID_ROBOT = 'API_RESET_CHECK_VALID_ROBOT';

export const API_GET_INVENTORY_ROBOT_INFO_REQUEST = 'API_GET_INVENTORY_ROBOT_INFO_REQUEST';
export const API_GET_INVENTORY_ROBOT_INFO_SUCCESS = 'API_GET_INVENTORY_ROBOT_INFO_SUCCESS';
export const API_GET_INVENTORY_ROBOT_INFO_FAILED = 'API_GET_INVENTORY_ROBOT_INFO_FAILED';

export const API_GET_ROBOT_LIVE_STATUS_REQUEST = 'API_GET_ROBOT_LIVE_STATUS_REQUEST';
export const API_GET_ROBOT_LIVE_STATUS_SUCCESS = 'API_GET_ROBOT_LIVE_STATUS_SUCCESS';
export const API_GET_ROBOT_LIVE_STATUS_FAILED = 'API_GET_ROBOT_LIVE_STATUS_FAILED';

export const API_CLEAR_ROBOT_LIVE_STATUS_REQUEST = 'API_CLEAR_ROBOT_LIVE_STATUS_REQUEST';
export const API_CLEAR_INVENTORY_ROBOT_INFO_REQUEST = 'API_CLEAR_INVENTORY_ROBOT_INFO_REQUEST';

export const API_GET_ALL_ORG_INSTANCE_REQUEST = 'API_GET_ALL_ORG_INSTANCE_REQUEST';
export const API_GET_ALL_ORG_INSTANCE_SUCCESS = 'API_GET_ALL_ORG_INSTANCE_SUCCESS';
export const API_GET_ALL_ORG_INSTANCE_FAILED = 'API_GET_ALL_ORG_INSTANCE_FAILED';

export const API_ONBOARD_ORG_INSTANCE_REQUEST = 'API_ONBOARD_ORG_INSTANCE_REQUEST';
export const API_ONBOARD_ORG_INSTANCE_SUCCESS = 'API_ONBOARD_ORG_INSTANCE_SUCCESS';
export const API_ONBOARD_ORG_INSTANCE_FAILED = 'API_ONBOARD_ORG_INSTANCE_FAILED';

export const API_UPDATE_ORG_INSTANCE_REQUEST = 'API_UPDATE_ORG_INSTANCE_REQUEST';
export const API_UPDATE_ORG_INSTANCE_SUCCESS = 'API_UPDATE_ORG_INSTANCE_SUCCESS';
export const API_UPDATE_ORG_INSTANCE_FAILED = 'API_UPDATE_ORG_INSTANCE_FAILED';

export const API_CREATE_SHOOT_INSTANCE_REQUEST = 'API_CREATE_SHOOT_INSTANCE_REQUEST';
export const API_CREATE_SHOOT_INSTANCE_SUCCESS = 'API_CREATE_SHOOT_INSTANCE_SUCCESS';
export const API_CREATE_SHOOT_INSTANCE_FAILED = 'API_CREATE_SHOOT_INSTANCE_FAILED';

export const API_GET_ALL_SHOOT_INSTANCE_REQUEST = 'API_GET_ALL_SHOOT_INSTANCE_REQUEST';
export const API_GET_ALL_SHOOT_INSTANCE_SUCCESS = 'API_GET_ALL_SHOOT_INSTANCE_SUCCESS';
export const API_GET_ALL_SHOOT_INSTANCE_FAILED = 'API_GET_ALL_SHOOT_INSTANCE_FAILED';

export const API_UPDATE_SHOOT_INSTANCE_REQUEST = 'API_UPDATE_SHOOT_INSTANCE_REQUEST';
export const API_UPDATE_SHOOT_INSTANCE_SUCCESS = 'API_UPDATE_SHOOT_INSTANCE_SUCCESS';
export const API_UPDATE_SHOOT_INSTANCE_FAILED = 'API_UPDATE_SHOOT_INSTANCE_FAILED';

export const API_DELETE_SHOOT_INSTANCE_REQUEST = 'API_DELETE_SHOOT_INSTANCE_REQUEST';
export const API_DELETE_SHOOT_INSTANCE_SUCCESS = 'API_DELETE_SHOOT_INSTANCE_SUCCESS';
export const API_DELETE_SHOOT_INSTANCE_FAILED = 'API_DELETE_SHOOT_INSTANCE_FAILED';

export const API_GET_ALL_SHOOT_INSTANCES_LIVE_STATUS_REQUEST = 'API_GET_ALL_SHOOT_INSTANCES_LIVE_STATUS_REQUEST';
export const API_GET_ALL_SHOOT_INSTANCES_LIVE_STATUS_SUCCESS = 'API_GET_ALL_SHOOT_INSTANCES_LIVE_STATUS_SUCCESS';
export const API_GET_ALL_SHOOT_INSTANCES_LIVE_STATUS_FAILED = 'API_GET_SHOOT_INSTANCE_LIVE_STATUS_FAILED';

export const API_GET_SHOOT_INSTANCE_LIVE_STATUS_REQUEST = 'API_GET_SHOOT_INSTANCE_LIVE_STATUS_REQUEST';
export const API_GET_SHOOT_INSTANCE_LIVE_STATUS_SUCCESS = 'API_GET_SHOOT_INSTANCE_LIVE_STATUS_SUCCESS';
export const API_GET_SHOOT_INSTANCE_LIVE_STATUS_FAILED = 'API_GET_SHOOT_INSTANCE_LIVE_STATUS_FAILED';

export const API_START_SHOOT_INSTANCE_REQUEST = 'API_START_SHOOT_INSTANCE_REQUEST';
export const API_START_SHOOT_INSTANCE_SUCCESS = 'API_START_SHOOT_INSTANCE_SUCCESS';
export const API_START_SHOOT_INSTANCE_FAILED = 'API_START_SHOOT_INSTANCE_FAILED';

export const API_STOP_SHOOT_INSTANCE_REQUEST = 'API_STOP_SHOOT_INSTANCE_REQUEST';
export const API_STOP_SHOOT_INSTANCE_SUCCESS = 'API_STOP_SHOOT_INSTANCE_SUCCESS';
export const API_STOP_SHOOT_INSTANCE_FAILED = 'API_STOP_SHOOT_INSTANCE_FAILED';

// DEPRECATED SECTION
// export const API_GET_INSTANCE_DETAILS_REQUEST = 'API_GET_INSTANCE_DETAILS_REQUEST';
// export const API_GET_INSTANCE_DETAILS_SUCCESS = 'API_GET_INSTANCE_DETAILS_SUCCESS';
// export const API_GET_INSTANCE_DETAILS_FAILED = 'API_GET_INSTANCE_DETAILS_FAILED';
// export const RESET_GEAR = 'RESET_GEAR';
// export const API_GEAR_POST_REQUEST = 'API_GEAR_POST_REQUEST';
// export const API_GEAR_POST_SUCCESS = 'API_GEAR_POST_SUCCESS';
// export const API_GEAR_POST_FAILED = 'API_GEAR_POST_FAILED';
// export const API_POST_CAMERA_DEACTIVATE_REQUEST = 'API_POST_CAMERA_DEACTIVATE_REQUEST';
// export const API_POST_CAMERA_DEACTIVATE_SUCCESS = 'API_POST_CAMERA_DEACTIVATE_SUCCESS';
// export const API_POST_CAMERA_DEACTIVATE_FAILED = 'API_POST_CAMERA_DEACTIVATE_FAILED';
// export const API_GET_CAMERA_DETAILS_REQUEST = 'API_GET_CAMERA_DETAILS_REQUEST';
// export const API_GET_CAMERA_DETAILS_SUCCESS = 'API_GET_CAMERA_DETAILS_SUCCESS';
// export const API_GET_CAMERA_DETAILS_FAILED = 'API_GET_CAMERA_DETAILS_FAILED';
// export const API_GEAR_LIST_REQUEST = 'API_GEAR_LIST_REQUEST';
// export const API_GEAR_LIST_SUCCESS = 'API_GEAR_LIST_SUCCESS';
// export const API_GEAR_LIST_FAILED = 'API_GEAR_LIST_FAILED';
// export const API_GEAR_GLOBAL_LIST_REQUEST = 'API_GEAR_GLOBAL_LIST_REQUEST';
// export const API_GEAR_GLOBAL_LIST_SUCCESS = 'API_GEAR_GLOBAL_LIST_SUCCESS';
// export const API_GEAR_GLOBAL_LIST_FAILED = 'API_GEAR_GLOBAL_LIST_FAILED';
// export const API_GEAR_ASSIGN_LIST_REQUEST = 'API_GEAR_ASSIGN_LIST_REQUEST';
// export const API_GEAR_ASSIGN_LIST_SUCCESS = 'API_GEAR_ASSIGN_LIST_SUCCESS';
// export const API_GEAR_ASSIGN_LIST_FAILED = 'API_GEAR_ASSIGN_LIST_FAILED';
// export const API_GEAR_ASSIGN_REQUEST = 'API_GEAR_ASSIGN_REQUEST';
// export const API_GEAR_ASSIGN_SUCCESS = 'API_GEAR_ASSIGN_SUCCESS';
// export const API_GEAR_ASSIGN_FAILED = 'API_GEAR_ASSIGN_FAILED';
// export const API_CAMERA_PATCH_REQUEST = 'API_CAMERA_PATCH_REQUEST';
// export const API_CAMERA_PATCH_SUCCESS = 'API_CAMERA_PATCH_SUCCESS';
// export const API_CAMERA_PATCH_FAILED = 'API_CAMERA_PATCH_FAILED';
// export const API_INSTANCE_PATCH_REQUEST = 'API_INSTANCE_PATCH_REQUEST';
// export const API_INSTANCE_PATCH_SUCCESS = 'API_INSTANCE_PATCH_SUCCESS';
// export const API_INSTANCE_DELETE_SUCCESS = 'API_INSTANCE_DELETE_SUCCESS';
// export const API_INSTANCE_PATCH_FAILED = 'API_INSTANCE_PATCH_FAILED';
// END