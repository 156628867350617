import * as types from '../constants/gear';
import { mapErrorMessage } from '../../Utils/mapErrorMessage';
import { toast } from 'react-toastify';
import parseDjangoError from '../../Utils/parseDjangoError';

const INITIAL_STATE = {
  isLoading: false,
  success: false,
  error: null,
  instanceDetails: null,
  instanceDetailsErr: null,

  // TESTING
  orgRobots: [],
  shootRobots: [],
  robotLiveStatus: [],
  validRobot: null,
  inventoryRobotInfo: {},
  orgInstances: [],
  shootInstances: [],
  shootInstanceLiveStatuses: [],

  // DEPRECATED SECTION
  // redirect_url: null, // Stripe Checkout url
  // airGearAssignedList: [],
  // airGearGlobalList: [],
  // cameraStatusList: [],
  // cameraDetails: null,
  // cameraDetailsErr: null,
  // gearList: [],
};

let toastId = null;

export default function gearApiReducer(state = INITIAL_STATE, action) {
  const newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case types.CLEAR_INSTANCE_DETAILS:
      return {
        ...state,
        instanceDetails: null,
        instanceDetailsErr: null,
      };

    // TESTING
    case types.API_RESET_ALL_GEARS:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: null,
        orgGears: [],
        orgInstances: [],
      };

    case types.API_GET_ALL_ORG_ROBOTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
      };
    case types.API_GET_ALL_ORG_ROBOTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        orgRobots: action.response.data.result,
      };
    case types.API_GET_ALL_ORG_ROBOTS_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };

    case types.API_ONBOARD_ORG_ROBOT_REQUEST:
      toastId = toast.loading('Onboarding Robot. Please wait a second...');
      return {
        ...state,
        isLoading: true,
        success: false,
      };
    case types.API_ONBOARD_ORG_ROBOT_SUCCESS:
      toast.update(toastId, {
        render: 'Robot onboarded successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        orgRobots: [action.response.data.result, ...state.orgRobots],
      };
    case types.API_ONBOARD_ORG_ROBOT_FAILED:
      toast.update(toastId, {
        render: 'Robot onboarded failed!',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };

    case types.API_UPDATE_ORG_ROBOT_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };
    case types.API_UPDATE_ORG_ROBOT_SUCCESS:
      toast.success('Successfully update Robot robot settings', {
        autoClose: 3000,
        closeOnClick: true,
      });
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        orgRobots: [
          ...state.orgRobots?.map(orgRobot =>
            orgRobot.id === action.response.data.result?.id
              ? action.response.data.result
              : orgRobot,
          ),
        ],
      };
    case types.API_UPDATE_ORG_ROBOT_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };

    case types.API_GET_ALL_SHOOT_ROBOT_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };
    case types.API_GET_ALL_SHOOT_ROBOT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        shootRobots: action.response.data.result,
      };
    case types.API_GET_ALL_SHOOT_ROBOT_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };

    case types.API_CREATE_SHOOT_ROBOT_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };
    case types.API_CREATE_SHOOT_ROBOT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        shootRobots: [action.response.data.result, ...state.shootRobots],
      };
    case types.API_CREATE_SHOOT_ROBOT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };

    case types.API_UPDATE_SHOOT_ROBOT_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };
    case types.API_UPDATE_SHOOT_ROBOT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        shootRobots: [
          ...state.shootRobots?.map(shootRobot =>
            shootRobot.id === action.response.data.result?.id
              ? action.response.data.result
              : shootRobot,
          ),
        ],
      };
    case types.API_UPDATE_SHOOT_ROBOT_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };

    case types.API_DELETE_SHOOT_ROBOT_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };
    case types.API_DELETE_SHOOT_ROBOT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case types.API_DELETE_SHOOT_ROBOT_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };

    case types.API_CHECK_VALID_ROBOT_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };
    case types.API_CHECK_VALID_ROBOT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        validRobot: action.response.data.result
      };
    case types.API_CHECK_VALID_ROBOT_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        validRobot: action.response.data.result
      };
    case types.API_RESET_CHECK_VALID_ROBOT:
      return {
        ...state,
        isLoading: false,
        success: false,
        validRobot: null
      };

    case types.API_GET_INVENTORY_ROBOT_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };
    case types.API_GET_INVENTORY_ROBOT_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        inventoryRobotInfo: action.response.data.result.length !== 0
          ? action.response.data.result[0]
          : {},
      };
    case types.API_GET_INVENTORY_ROBOT_INFO_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };

    case types.API_GET_ROBOT_LIVE_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };
    case types.API_GET_ROBOT_LIVE_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        robotLiveStatus: action.response.data.result,
      };
    case types.API_GET_ROBOT_LIVE_STATUS_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };

    case types.API_CLEAR_ROBOT_LIVE_STATUS_REQUEST:
      return {
        ...state,
        robotLiveStatus: [],
      };
    case types.API_CLEAR_INVENTORY_ROBOT_INFO_REQUEST:
      return {
        ...state,
        inventoryRobotInfo: {},
      };
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };


    case types.API_GET_ALL_ORG_INSTANCE_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
      };
    case types.API_GET_ALL_ORG_INSTANCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        orgInstances: action.response.data.result,
      };
    case types.API_GET_ALL_ORG_INSTANCE_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };

    case types.API_ONBOARD_ORG_INSTANCE_REQUEST:
      toastId = toast.loading('Onboarding Instance. Please wait a second...');
      return {
        ...state,
        isLoading: true,
        success: false,
      };
    case types.API_ONBOARD_ORG_INSTANCE_SUCCESS:
      toast.update(toastId, {
        render: 'Instance onboarded successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        orgInstances: [action.response.data.result, ...state.orgInstances],
      };
    case types.API_ONBOARD_ORG_INSTANCE_FAILED:
      toast.update(toastId, {
        render: 'Instance onboarded failed!',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };

    case types.API_UPDATE_ORG_INSTANCE_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };
    case types.API_UPDATE_ORG_INSTANCE_SUCCESS:
      toast.success('Successfully update Instance settings', {
        autoClose: 3000,
        closeOnClick: true,
      });
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        orgInstances: [
          ...state.orgInstances?.map(airInstance =>
            airInstance.id === action.response.data.result?.id
              ? action.response.data.result
              : airInstance,
          ),
        ],
      };
    case types.API_UPDATE_ORG_INSTANCE_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };

    case types.API_GET_ALL_SHOOT_INSTANCE_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };
    case types.API_GET_ALL_SHOOT_INSTANCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        shootInstances: action.response.data.result,
      };
    case types.API_GET_ALL_SHOOT_INSTANCE_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };

    case types.API_CREATE_SHOOT_INSTANCE_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };
    case types.API_CREATE_SHOOT_INSTANCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        shootInstances: [action.response.data.result, ...state.shootInstances],
      };
    case types.API_CREATE_SHOOT_INSTANCE_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };

    case types.API_UPDATE_SHOOT_INSTANCE_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };
    case types.API_UPDATE_SHOOT_INSTANCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        shootInstances: [
          ...state.shootInstances?.map(airInstanceAssignedToProject =>
            airInstanceAssignedToProject.id === action.response.data.result?.id
              ? action.response.data.result
              : airInstanceAssignedToProject,
          ),
        ],
      };
    case types.API_UPDATE_SHOOT_INSTANCE_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };

    case types.API_DELETE_SHOOT_INSTANCE_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };
    case types.API_DELETE_SHOOT_INSTANCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case types.API_DELETE_SHOOT_INSTANCE_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.response.data.error,
      };

    case types.API_GET_ALL_SHOOT_INSTANCES_LIVE_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };
    case types.API_GET_ALL_SHOOT_INSTANCES_LIVE_STATUS_SUCCESS:
      if (action.response.data.error.length) {
        toast.error(action.response.data.error);
      }
      return {
        ...state,
        isLoading: false,
        success: true,
        shootInstanceLiveStatuses: action.response.data.result,
      };
    case types.API_GET_ALL_SHOOT_INSTANCES_LIVE_STATUS_FAILED:
      toast.error(action.error.message)
      return {
        ...state,
        success: false,
        error: action.error.message,
      };

    case types.API_GET_SHOOT_INSTANCE_LIVE_STATUS_REQUEST:
      return {
        ...state,
        success: false,
        error: null,
      };
    case types.API_GET_SHOOT_INSTANCE_LIVE_STATUS_SUCCESS:
      return {
        ...state,
        success: true,
        shootInstanceLiveStatuses: [
          ...state.shootInstanceLiveStatuses?.map(shootInstanceLiveStatus =>
          shootInstanceLiveStatus.air_id === action.response.data.result?.air_id
            ? { ...shootInstanceLiveStatus, ...action.response.data.result }
            : shootInstanceLiveStatus
        )]
      };
    case types.API_GET_SHOOT_INSTANCE_LIVE_STATUS_FAILED:
      toast.error(action.error.message)
      return {
        ...state,
        success: false,
        error: action.error.message,
      };

    case types.API_START_SHOOT_INSTANCE_REQUEST:
      return {
        ...state,
        success: false,
        error: null,
      };
    case types.API_START_SHOOT_INSTANCE_SUCCESS:
      toast.success(action.response.data.result)
      return {
        ...state,
        success: true,
      };
    case types.API_START_SHOOT_INSTANCE_FAILED:
      toast.error(action.error.message)
      return {
        ...state,
        success: false,
        error: action.error.message,
      };

    case types.API_STOP_SHOOT_INSTANCE_REQUEST:
      return {
        ...state,
        success: false,
        error: null,
      };
    case types.API_STOP_SHOOT_INSTANCE_SUCCESS:
      toast.success(action.response.data.result)
      return {
        ...state,
        success: true,
      };
    case types.API_STOP_SHOOT_INSTANCE_FAILED:
      toast.error(action.error.message)
      return {
        ...state,
        success: false,
        error: action.error.message,
      };

    // DEPRECATED SECTION
    // case types.API_GET_INSTANCE_DETAILS_REQUEST:
    //   return {
    //     ...state,
    //     isLoading: true,
    //     success: false,
    //     error: null,
    //     instanceDetails: null,
    //     instanceDetailsErr: null,
    //   };
    // case types.API_GET_INSTANCE_DETAILS_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     success: true,
    //     error: null,
    //     instanceDetails: action?.response?.data?.data
    //       ? action?.response?.data?.data
    //       : null,
    //     instanceDetailsErr: action.response.data.error
    //       ? action.response.data.error
    //       : null,
    //   };
    // case types.API_GET_INSTANCE_DETAILS_FAILED:
    //   const error = mapErrorMessage(action);
    //   return {
    //     ...state,
    //     isLoading: false,
    //     success: false,
    //     instanceDetailsErr: error?.message,
    //     error,
    //   };

    // case types.API_GEAR_POST_REQUEST:
    //   toastId = toast.loading('Please wait a second...');
    //   return {
    //     ...state,
    //     isLoading: true,
    //     success: false,
    //     error: null,
    //   };

    // case types.API_GEAR_POST_SUCCESS:
    //   toast.update(toastId, {
    //     render: 'Gear added successfully!',
    //     type: 'success',
    //     isLoading: false,
    //     autoClose: 5000,
    //     closeOnClick: true,
    //   });
    //   return {
    //     ...state,
    //     isLoading: false,
    //     success: true,
    //     error: null,
    //     // gearList: [action.response.data.data, ...state.gearList],
    //   };

    // case types.API_GEAR_POST_FAILED:
      // const clear_url = () => {
      //   setTimeout(() => {
      //     newState.redirect_url = null;
      //   }, 3000);
      // };
      // clear_url();
      // if (!action.response.response.data.data.url) {
      //   toast.update(toastId, {
      //     render: action.response.response.data?.error?.air_id
      //       ? parseDjangoError(action.response.response.data.error.air_id[0])
      //       : action.response.response.data?.error
      //       ? parseDjangoError(action.response.response.data.error)
      //       : parseDjangoError(action.response.response.data),
      //     type: 'error',
      //     isLoading: false,
      //     autoClose: 5000,
      //     closeOnClick: true,
      //   });
      // }
      // if (action.response.response.data.data.url) {
      //   console.log('redirect_url: ' + action.response.response.data.data.url);
      //   console.log('action: ' + JSON.stringify(action));
      //   console.log('action.response: ' + JSON.stringify(action.response));
      //   console.log('action.response.response: ' + JSON.stringify(action.response.response));
      // }

      // return {
      //   ...state,
      //   isLoading: false,
      //   success: false,
      //   redirect_url: action.response.response.data.data.url,
      //   error: mapErrorMessage(action),
      // };
    // case types.RESET_GEAR:
        // return {
        //   ...state,
        //   isLoading: false,
        //   success: false,
        //   error: null,
        // };
    // case types.API_GEAR_LIST_REQUEST:
    //   return {
    //     ...state,
    //     isLoading: true,
    //     success: false,
    //     error: null,
    //   };
    // case types.API_GEAR_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     success: true,
    //     error: null,
    //     gearList: action.response.data.results,
    //   };
    // case types.API_GEAR_LIST_FAILED:
      // return {
      //   ...state,
      //   isLoading: false,
      //   success: false,
      //   error: mapErrorMessage(action),
      // };

    // case types.API_GEAR_ASSIGN_LIST_REQUEST:
    //   return {
    //     ...state,
    //     isLoading: true,
    //     success: false,
    //     error: null,
    //   };
    // case types.API_GEAR_ASSIGN_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     success: true,
    //     error: null,
    //     airGearAssignedList: action.response.data.data,
    //   };
    // case types.API_GEAR_ASSIGN_LIST_FAILED:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     success: false,
    //     error: mapErrorMessage(action),
    //   };
    // case types.API_CAMERA_PATCH_REQUEST:
    //   // console.log('patch req', action);
    //   toastId = toast.loading('Please wait...');
    //   return {
    //     ...state,
    //     isLoading: true,
    //     success: false,
    //     error: null,
    //   };

    // case types.API_CAMERA_PATCH_SUCCESS:
    //   // console.log('success', action);
    //   toast.update(toastId, {
    //     render: 'Camera edit successfully!',
    //     type: 'success',
    //     isLoading: false,
    //     autoClose: 2000,
    //     closeOnClick: true,
    //   });
    //   return {
    //     ...state,
    //     isLoading: false,
    //     success: true,
    //     error: null,
    //     // gearList: [action.response.data.data, ...state.gearList],
    //   };

    // case types.API_CAMERA_PATCH_FAILED:
      // console.log('fail', action);
      // toast.update(toastId, {
      //   render: parseDjangoError(action.response.response.data.error),
      //   type: 'error',
      //   isLoading: false,
      //   autoClose: 2000,
      //   closeOnClick: true,
      // });
      // return {
      //   ...state,
      //   isLoading: false,
      //   success: false,
      //   error: mapErrorMessage(action),
      // };
      // Edit Instance
      // case types.API_INSTANCE_PATCH_REQUEST:
      //   toastId = toast.loading('Please wait...');
      //   return {
      //     ...state,
      //     isLoading: true,
      //     success: false,
      //     error: null,
      //   };

      // case types.API_INSTANCE_PATCH_SUCCESS:
      //   toast.update(toastId, {
      //     render: 'Instance edit successfully!',
      //     type: 'success',
      //     isLoading: false,
      //     autoClose: 2000,
      //     closeOnClick: true,
      //   });
      //   return {
      //     ...state,
      //     isLoading: false,
      //     success: true,
      //     error: null,
      //     gearList: [action.response.data.data, ...state.gearList],
      //   };

      // case types.API_INSTANCE_DELETE_SUCCESS:
      //   toast.update(toastId, {
      //     render: 'Instance delete successfully!',
      //     type: 'success',
      //     isLoading: false,
      //     autoClose: 2000,
      //     closeOnClick: true,
      //   });
      //   return {
      //     ...state,
      //     isLoading: false,
      //     success: true,
      //     error: null,
      //     gearList: [action.response.data.data, ...state.gearList],
      //   };

      // case types.API_INSTANCE_PATCH_FAILED:
      //   toast.update(toastId, {
      //     render: parseDjangoError(action.response.response.data.error),
      //     type: 'error',
      //     isLoading: false,
      //     autoClose: 2000,
      //     closeOnClick: true,
      //   });
      //   return {
      //     ...state,
      //     isLoading: false,
      //     success: false,
      //     error: mapErrorMessage(action),
      //   };

      // END
    // case types.API_GEAR_GLOBAL_LIST_REQUEST:
    //   return {
    //     ...state,
    //     isLoading: true,
    //     success: false,
    //     error: null,
    //   };
    // case types.API_GEAR_GLOBAL_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     success: true,
    //     error: null,
    //     airGearGlobalList: action.response.data.data,
    //   };

    // case types.API_GEAR_GLOBAL_LIST_FAILED:
      // return {
      //   ...state,
      //   isLoading: false,
      //   success: false,
      //   error: mapErrorMessage(action),
      // };
    // case types.API_GET_CAMERA_DETAILS_REQUEST:
    //   return {
    //     ...state,
    //     isLoading: true,
    //     success: false,
    //     error: null,
    //     cameraDetails: null,
    //     cameraDetailsErr: null,
    //   };
    // case types.API_GET_CAMERA_DETAILS_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     success: true,
    //     error: null,
    //     cameraDetails: action.response.data.result
    //       ? action.response.data.result
    //       : null,
    //     cameraDetailsErr: action.response.data.error
    //       ? action.response.data.error
    //       : null,
    //   };
    // case types.API_GET_CAMERA_DETAILS_FAILED:
      // return {
      //   ...state,
      //   isLoading: false,
      //   success: false,
      //   error: mapErrorMessage(action),
      // };
    // case types.API_POST_CAMERA_DEACTIVATE_REQUEST:
    //   toastId = toast.loading('Please wait...');
    //   return {
    //     ...state,
    //     isLoading: true,
    //     success: false,
    //     error: null,
    //   };
    // case types.API_POST_CAMERA_DEACTIVATE_SUCCESS:
    //   toast.update(toastId, {
    //     render: 'Camera deactivated successfully!',
    //     type: 'success',
    //     isLoading: false,
    //     autoClose: 2000,
    //     closeOnClick: true,
    //   });
    //   return {
    //     ...state,
    //     isLoading: false,
    //     success: true,
    //     error: null,
    //   };
    // case types.API_POST_CAMERA_DEACTIVATE_FAILED:
      // toast.update(toastId, {
      //   render: parseDjangoError(action.response.response.data.error),
      //   type: 'error',
      //   isLoading: false,
      //   autoClose: 2000,
      //   closeOnClick: true,
      // });
      // return {
      //   ...state,
      //   isLoading: false,
      //   success: false,
      //   error: mapErrorMessage(action),
      // };
    default:
      return state;
  }
}