import React, { useEffect, useState } from 'react';
import {
  Container,
  RoleText,
  InstanceContainer,
  UserContainer,
  OnlineStatus,
  OflineStatus,
} from './styles';
import { Avatar } from '../CommonStyles';
import Icon from './Icon';
import acrynom from '../../Utils/acrynom';
import trancate from '../../Utils/trancate';
import * as types from 'Config/permissionConstant';

const UserAssignComponent = ({ image, name, isOnline }) => {
  return (
    <UserContainer>
      <Avatar bgImage={image}>{image ? null : acrynom(name)}</Avatar>
      {isOnline ? <OnlineStatus /> : <OflineStatus />}
    </UserContainer>
  );
};
const BottomInstanceNav = ({
  instances,
  onlineMembers,
  onDrop,
  onContextMenu,
  myPermission,
  crew_target_key,
  cam_target_key,
  // data: { id, lan_ip },
  onDblClick = () => {},
  onClick = () => {},
  popup = () => {},
  shootInstanceLiveStatuses,
}) => {
  const order_one = instances?.filter(instance => instance.order === 1);
  const order_two = instances?.filter(instance => instance.order === 2);
  const order_three = instances?.filter(instance => instance.order === 3);

  const [orderOneIsActive, setOrderOneIsActive] = useState(false);
  const [orderTwoIsActive, setOrderTwoIsActive] = useState(false);
  const [orderThreeIsActive, setOrderThreeIsActive] = useState(false);

  const ondblClickFunc = order => {
    let instancesArr = instances;
    instancesArr = instancesArr.filter(f => f.order === order);
    let instances_id = instancesArr[0].id;
    // console.log('instances 2', instances_lan_ip, instances_id);
    onDblClick(instances_id, instancesArr[0], 'instance');
  };

  const onClickFunc = order => {
    let selected_instance = order === 1 ? order_one[0]
                  : order === 2 ? order_two [0]
                  : order_three[0]
    onClick(selected_instance.air_id);
  };

  useEffect(() => {
    if (shootInstanceLiveStatuses?.length) {
      if (order_one?.length) {
        let orderOneInstanceStatusArr = shootInstanceLiveStatuses.filter(instanceStatus=> instanceStatus.air_id === order_one[0].air_id);
        if (orderOneInstanceStatusArr?.length && orderOneInstanceStatusArr[0]?.status === "Running" || orderOneInstanceStatusArr[0]?.status === "Pending") {
          setOrderOneIsActive(true);
        } else {
          setOrderOneIsActive(false);
        }
      }
      if (order_two?.length) {
        let orderTwoInstanceStatusArr = shootInstanceLiveStatuses.filter(instanceStatus=> instanceStatus.air_id === order_two[0].air_id);
        if (orderTwoInstanceStatusArr?.length && orderTwoInstanceStatusArr[0]?.status === "Running" || orderTwoInstanceStatusArr[0]?.status === "Pending") {
          setOrderTwoIsActive(true);
        } else {
          setOrderTwoIsActive(false);
        }
      }
      if (order_three?.length) {
        let orderThreeInstanceStatusArr = shootInstanceLiveStatuses.filter(instanceStatus=> instanceStatus.air_id === order_three[0].air_id);
        if (orderThreeInstanceStatusArr?.length && orderThreeInstanceStatusArr[0]?.status === "Running" || orderThreeInstanceStatusArr[0]?.status === "Pending") {
          setOrderThreeIsActive(true);
        } else {
          setOrderThreeIsActive(false);
        }
      }
    }
  }, [shootInstanceLiveStatuses]);

  let lan_ip = 1;
  let id = 2;
  return (
    <Container>
      {/* <DropTarget
        targetKey={crew_target_key}
        onDragLeave={e => {
          onDrop(e, 1, order_one.length ? order_one[0].id : null);
        }}
        onHit={e => {
          // alert('You put the orange in the box!');
        }}
        // e.preventDefault();
      >
        <DropTarget
          targetKey={cam_target_key}
          // onDragLeave={e => {
          //   console.log('Drag Leave', e);
          //   onDrop(e, 1, order_one.length ? order_one[0].id : null);
          //   console.log('onDragLeave Inst', e);
          // }}
          onHit={e => {
            // alert('You put the orange in the box!');
          }}
          // e.preventDefault();
        > */}
      <InstanceContainer
        onDragOver={e => e.preventDefault()}
        onDrop={e => {
          onDrop(e, 1, order_one.length ? order_one[0].id : null);
        }}
        onClick={order_one?.length ? () => onClickFunc(1) : null}
        onDoubleClick={order_one?.length ? () => ondblClickFunc(1) : null}
        onTouchEnd={order_one?.length ? () => ondblClickFunc(1) : null}
        onContextMenu={
          myPermission?.includes(types.EDIT_SETUP_SCREEN)
            ? order_one?.length
              ? e => {
                  e.preventDefault();
                  popup(order_one[0]);
                }
              : e => {
                  e.preventDefault();
                }
            : e => {
                e.preventDefault();
              }
        }
      >
        {order_one?.length ? (
          <>
            <RoleText>{trancate(order_one[0].nick_name, 9)}</RoleText>
            <Icon active instanceIsLive={orderOneIsActive}/>
            {order_one[0].user ? (
              <UserAssignComponent
                name={order_one[0].user.name}
                image={order_one[0].user.profile_image}
                isOnline={onlineMembers?.find(
                  item => item[order_one[0]?.user?.id] === true,
                )}
              />
            ) : null}
          </>
        ) : (
          <>
            {/* <RoleText>Switcher</RoleText> */}
            <RoleText>Instance</RoleText>
            <Icon />
          </>
        )}
      </InstanceContainer>
      {/* </DropTarget>
      </DropTarget> */}

      {/* <DropTarget
        targetKey={cam_target_key}
        onDragLeave={e => {
          onDrop(e, 2, order_two.length ? order_two[0].id : null);
        }}
        onHit={e => {
          // alert('You put the orange in the box!');
        }}
        // e.preventDefault();
      >
        <DropTarget
          targetKey={crew_target_key}
          onDragLeave={e => {
            onDrop(e, 2, order_two.length ? order_two[0].id : null);
          }}
          onHit={e => {
            // alert('You put the orange in the box!');
          }}
          // e.preventDefault();
        > */}
      <InstanceContainer
        onDragOver={e => e.preventDefault()}
        onDrop={e => {
          onDrop(e, 2, order_two.length ? order_two[0].id : null);
        }}
        onClick={order_two?.length ? () => onClickFunc(2) : null}
        onDoubleClick={order_two?.length ? () => ondblClickFunc(2) : null}
        onTouchEnd={order_two?.length ? () => ondblClickFunc(2) : null}
        onContextMenu={
          myPermission?.includes(types.EDIT_SETUP_SCREEN)
            ? order_two?.length
              ? e => {
                  e.preventDefault();
                  popup(order_two[0]);
                }
              : e => {
                  e.preventDefault();
                }
            : e => {
                e.preventDefault();
              }
        }
      >
        {order_two?.length ? (
          <>
            <RoleText>{trancate(order_two[0].nick_name, 9)}</RoleText>
            <Icon active instanceIsLive={orderTwoIsActive}/>
            {order_two[0].user ? (
              <UserAssignComponent
                name={order_two[0].user.name}
                image={order_two[0].user.profile_image}
                isOnline={onlineMembers?.find(
                  item => item[order_two[0]?.user?.id] === true,
                )}
              />
            ) : null}
          </>
        ) : (
          <>
            {/* <RoleText>Switcher</RoleText> */}
            <RoleText>Instance</RoleText>
            <Icon />
          </>
        )}
      </InstanceContainer>
      {/* </DropTarget>
      </DropTarget> */}

      {/* <DropTarget
        targetKey={cam_target_key}
        onDragLeave={e => {
          onDrop(e, 3, order_three.length ? order_three[0].id : null);
        }}
        onHit={e => {
          // alert('You put the orange in the box!');
        }}
        // e.preventDefault();
      >
        <DropTarget
          targetKey={crew_target_key}
          onDragLeave={e => {
            onDrop(e, 3, order_three.length ? order_three[0].id : null);
          }}
          onHit={e => {
            // alert('You put the orange in the box!');
          }}
          // e.preventDefault();
        > */}
      <InstanceContainer
        onDragOver={e => e.preventDefault()}
        onDrop={e => {
          onDrop(e, 3, order_three.length ? order_three[0].id : null);
        }}
        onClick={order_three?.length ? () => onClickFunc(3) : null}
        onDoubleClick={order_three?.length ? () => ondblClickFunc(3) : null}
        onTouchEnd={order_three?.length ? () => ondblClickFunc(3) : null}
        onContextMenu={
          myPermission?.includes(types.EDIT_SETUP_SCREEN)
            ? order_three?.length
              ? e => {
                  e.preventDefault();
                  popup(order_three[0]);
                }
              : e => {
                  e.preventDefault();
                }
            : e => {
                e.preventDefault();
              }
        }
      >
        {order_three?.length ? (
          <>
            <RoleText onClick={onContextMenu}>
              {trancate(order_three[0].nick_name, 9)}
            </RoleText>
            <Icon active instanceIsLive={orderThreeIsActive}/>
            {order_three[0].user ? (
              <UserAssignComponent
                name={order_three[0].user.name}
                image={order_three[0].user.profile_image}
                isOnline={onlineMembers?.find(
                  item => item[order_three[0]?.user?.id] === true,
                )}
              />
            ) : null}
          </>
        ) : (
          <>
            {/* <RoleText>Switcher</RoleText> */}
            <RoleText>Instance</RoleText>
            <Icon />
          </>
        )}
      </InstanceContainer>
      {/* </DropTarget>
      </DropTarget> */}

      {/* <InstanceContainer
        onDragOver={e => e.preventDefault()}
        onDrop={e => {
          // onDrop(e, ROLES_BY_NAME.DIRECTOR);
        }}
        style={{ marginRight: 15 }}
      >
        <RoleText>vMix 32...</RoleText>
        <Icon active />
        <UserAssignComponent first_name={'Sheehab'} last_name={'Pranto'} />
      </InstanceContainer>
      <InstanceContainer
        onDragOver={e => e.preventDefault()}
        onDrop={e => {
          // onDrop(e, ROLES_BY_NAME.DIRECTOR);
        }}
      >
        <RoleText>Switcher</RoleText>
        <Icon />
      </InstanceContainer> */}
    </Container>
  );
};

export default BottomInstanceNav;
