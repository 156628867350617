import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../Modal';
import { Modal as ReactModal, Button, Container, Row, Col } from 'react-bootstrap';
import { apiStartShootInstanceRequest, apiStopShootInstanceRequest } from 'Redux/actions/gear';
import errorIcon from 'Assets/icons/errorIcon.svg';
import { ModalFooter } from '../CommonStyles';

const InstanceLauncherPopup = ({ instance, showInstanceLaunchModal, closeInstanceLaunchModal, props }) => {
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);

  const [confirmStopModalShow, setConfirmStopModalShow] = useState(false);

  const onCloseBothModals = () => {
    setConfirmStopModalShow(false);
    closeInstanceLaunchModal();
  }

  const handleStartStopAction = () => {
    if (!(instance.status === "Running" || instance.status ===  "Pending")) {
      dispatch(apiStartShootInstanceRequest(auth.token, { id: instance.air_id, region_name: instance.region_name }));
      closeInstanceLaunchModal();
    } else {
      setConfirmStopModalShow(true);
    }
  };

  const executeStopInstance = () => {
    dispatch(apiStopShootInstanceRequest(auth.token, { id: instance.air_id, region_name: instance.region_name }));
    onCloseBothModals();
  };

  const confirmStopModalFooter = (
    <ModalFooter>
      <Button onClick={() => setConfirmStopModalShow(false)} variant="light">
        No, Cancel
      </Button>
      <Button onClick={executeStopInstance} variant="danger">
        Yes, Stop
      </Button>
    </ModalFooter>
  );

  return (
    <>
      <ReactModal
        {...props}
        show={showInstanceLaunchModal}
        onHide={onCloseBothModals}
        backdrop="static"
        centered
      >
        <ReactModal.Header className="p-0" closeButton>
          <ReactModal.Title>
            EC2 Instance: {instance.name}
          </ReactModal.Title>
        </ReactModal.Header>

        <ReactModal.Body className="p-2">
            <Container>
              <Row>
                <Col>Organization: {instance.organization}</Col>
              </Row>
              <Row>
                <Col>IP address: {instance.public_ip}</Col>
              </Row>
              <Row>
                <Col>Status: {instance.status}</Col>
              </Row>
              <Row>
                <Col>Region: {instance.region_name}</Col>
              </Row>
              <Row>
                <Col>Up time: {instance.up_time}</Col>
              </Row>
            </Container>
        </ReactModal.Body>

        <ReactModal.Footer className="d-flex justify-content-center p-0">
          <Button
            onClick={handleStartStopAction}
          >
            {(instance.status === 'Running' || instance.status ===  "Pending") ? 'Stop Instance' : 'Start Instance'}
          </Button>
        </ReactModal.Footer>
      </ReactModal>

      <Modal
        show={confirmStopModalShow}
        onHide={() => setConfirmStopModalShow(false)}
        icon={errorIcon}
        title={'Stop the Instance'}
        body={'Are you sure you want to shut down this EC2 instance?'}
        footer={confirmStopModalFooter}
      />
    </>

  );
};

export default InstanceLauncherPopup;