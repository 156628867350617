import * as types from '../constants/gear';

export const clearInstanceDetails = response => ({
  type: types.CLEAR_INSTANCE_DETAILS,
});

// TESTING
export const resetAllGears = () => ({
  type: types.API_RESET_ALL_GEARS,
});

export const apiGetAllOrgRobotsRequest = (token, organisationId) => ({
  type: types.API_GET_ALL_ORG_ROBOTS_REQUEST,
  token,
  organisationId,
});
export const apiGetAllOrgRobotsSuccess = response => ({
  type: types.API_GET_ALL_ORG_ROBOTS_SUCCESS,
  response,
});
export const apiGetAllOrgRobotsFailed = response => ({
  type: types.API_GET_ALL_ORG_ROBOTS_FAILED,
  response,
});

export const apiOnboardOrgRobotRequest = (token, robotData) => ({
  type: types.API_ONBOARD_ORG_ROBOT_REQUEST,
  token,
  robotData,
});
export const apiOnboardOrgRobotSuccess = response => ({
  type: types.API_ONBOARD_ORG_ROBOT_SUCCESS,
  response,
});
export const apiOnboardOrgRobotFailed = response => ({
  type: types.API_ONBOARD_ORG_ROBOT_FAILED,
  response,
});

export const apiUpdateOrgRobotRequest = (token, robotData) => ({
  type: types.API_UPDATE_ORG_ROBOT_REQUEST,
  token,
  robotData,
});
export const apiUpdateOrgRobotSuccess = response => ({
  type: types.API_UPDATE_ORG_ROBOT_SUCCESS,
  response,
});
export const apiUpdateOrgRobotFailed = response => ({
  type: types.API_UPDATE_ORG_ROBOT_FAILED,
  response,
});

export const apiCreateShootRobotRequest = (token, robotData) => ({
  type: types.API_CREATE_SHOOT_ROBOT_REQUEST,
  token,
  robotData,
});
export const apiCreateShootRobotSuccess = response => ({
  type: types.API_CREATE_SHOOT_ROBOT_SUCCESS,
  response,
});
export const apiCreateShootRobotFailed = response => ({
  type: types.API_CREATE_SHOOT_ROBOT_SUCCESS,
  response,
});

export const apiGetAllShootRobotsRequest = (token, projectId) => ({
  type: types.API_GET_ALL_SHOOT_ROBOT_REQUEST,
  token,
  projectId,
});
export const apiGetAllShootRobotsSuccess = response => ({
  type: types.API_GET_ALL_SHOOT_ROBOT_SUCCESS,
  response,
});
export const apiGetAllShootRobotsFailed = response => ({
  type: types.API_GET_ALL_SHOOT_ROBOT_FAILED,
  response,
});

export const apiUpdateShootRobotRequest = (token, robotId, updateData) => ({
  type: types.API_UPDATE_SHOOT_ROBOT_REQUEST,
  token,
  robotId,
  updateData,
});
export const apiUpdateShootRobotSuccess = response => ({
  type: types.API_UPDATE_SHOOT_ROBOT_SUCCESS,
  response,
});
export const apiUpdateShootRobotFailed = response => ({
  type: types.API_UPDATE_SHOOT_ROBOT_FAILED,
  response,
});

export const apiDeleteShootRobotRequest = (token, robotId) => ({
  type: types.API_DELETE_SHOOT_ROBOT_REQUEST,
  token,
  robotId,
});
export const apiDeleteShootRobotSuccess = response => ({
  type: types.API_DELETE_SHOOT_ROBOT_SUCCESS,
  response,
});
export const apiDeleteShootRobotFailed = response => ({
  type: types.API_DELETE_SHOOT_ROBOT_FAILED,
  response,
});

export const apiCheckValidRobotRequest = (token, airId) => ({
  type: types.API_CHECK_VALID_ROBOT_REQUEST,
  token,
  airId,
});
export const apiCheckValidRobotSuccess = response => ({
  type: types.API_CHECK_VALID_ROBOT_SUCCESS,
  response,
});
export const apiCheckValidRobotFailed = response => ({
  type: types.API_CHECK_VALID_ROBOT_FAILED,
  response,
});
export const apiResetCheckValidRobot = () => ({
  type: types.API_RESET_CHECK_VALID_ROBOT,
});

export const apiGetInventoryRobotInfoRequest = (token, robotId) => ({
  type: types.API_GET_INVENTORY_ROBOT_INFO_REQUEST,
  token,
  robotId,
});
export const apiGetInventoryRobotInfoSuccess = response => ({
  type: types.API_GET_INVENTORY_ROBOT_INFO_SUCCESS,
  response,
});
export const apiGetInventoryRobotInfoFailed = response => ({
  type: types.API_GET_INVENTORY_ROBOT_INFO_FAILED,
  response,
});

export const apiGetRobotLiveStatusRequest = (token, robotIds) => ({
  type: types.API_GET_ROBOT_LIVE_STATUS_REQUEST,
  token,
  robotIds, // Contains: air_ids
});
export const apiGetRobotLiveStatusSuccess = response => ({
  type: types.API_GET_ROBOT_LIVE_STATUS_SUCCESS,
  response,
});
export const apiGetRobotLiveStatusFailed = response => ({
  type: types.API_GET_ROBOT_LIVE_STATUS_FAILED,
  response,
});

export const apiClearRobotLiveStatusRequest = () => ({
  type: types.API_CLEAR_ROBOT_LIVE_STATUS_REQUEST,
});
export const apiClearInventoryRobotInfoRequest = () => ({
  type: types.API_CLEAR_INVENTORY_ROBOT_INFO_REQUEST,
});

export const apiGetAllOrgInstanceRequest = (token, organisationId) => ({
  type: types.API_GET_ALL_ORG_INSTANCE_REQUEST,
  token,
  organisationId,
});
export const apiGetAllOrgInstanceSuccess = response => ({
  type: types.API_GET_ALL_ORG_INSTANCE_SUCCESS,
  response,
});
export const apiGetAllOrgInstanceFailed = response => ({
  type: types.API_GET_ALL_ORG_INSTANCE_FAILED,
  response,
});

export const apiOnboardOrgInstanceRequest = (token, instanceData) => ({
  type: types.API_ONBOARD_ORG_INSTANCE_REQUEST,
  token,
  instanceData,
});
export const apiOnboardOrgInstanceSuccess = response => ({
  type: types.API_ONBOARD_ORG_INSTANCE_SUCCESS,
  response,
});
export const apiOnboardOrgInstanceFailed = response => ({
  type: types.API_ONBOARD_ORG_INSTANCE_FAILED,
  response,
});

export const apiUpdateOrgInstanceRequest = (token, instanceData) => ({
  type: types.API_UPDATE_ORG_INSTANCE_REQUEST,
  token,
  instanceData,
});
export const apiUpdateOrgInstanceSuccess = response => ({
  type: types.API_UPDATE_ORG_INSTANCE_SUCCESS,
  response,
});
export const apiUpdateOrgInstanceFailed = response => ({
  type: types.API_UPDATE_ORG_INSTANCE_FAILED,
  response,
});

export const apiCreateShootInstanceRequest = (token, instanceData) => ({
  type: types.API_CREATE_SHOOT_INSTANCE_REQUEST,
  token,
  instanceData,
});
export const apiCreateShootInstanceSuccess = response => ({
  type: types.API_CREATE_SHOOT_INSTANCE_SUCCESS,
  response,
});
export const apiCreateShootInstanceFailed = response => ({
  type: types.API_CREATE_SHOOT_INSTANCE_SUCCESS,
  response,
});

export const apiGetAllShootInstanceRequest = (token, projectId) => ({
  type: types.API_GET_ALL_SHOOT_INSTANCE_REQUEST,
  token,
  projectId,
});
export const apiGetAllShootInstanceSuccess = response => ({
  type: types.API_GET_ALL_SHOOT_INSTANCE_SUCCESS,
  response,
});
export const apiGetAllShootInstanceFailed = response => ({
  type: types.API_GET_ALL_SHOOT_INSTANCE_FAILED,
  response,
});

export const apiUpdateShootInstanceRequest = (token, instanceId, updateData) => ({
  type: types.API_UPDATE_SHOOT_INSTANCE_REQUEST,
  token,
  instanceId,
  updateData,
});
export const apiUpdateShootInstanceSuccess = response => ({
  type: types.API_UPDATE_SHOOT_INSTANCE_SUCCESS,
  response,
});
export const apiUpdateShootInstanceFailed = response => ({
  type: types.API_UPDATE_SHOOT_INSTANCE_FAILED,
  response,
});

export const apiDeleteShootInstanceRequest = (token, instanceId) => ({
  type: types.API_DELETE_SHOOT_INSTANCE_REQUEST,
  token,
  instanceId,
});
export const apiDeleteShootInstanceSuccess = response => ({
  type: types.API_DELETE_SHOOT_INSTANCE_SUCCESS,
  response,
});
export const apiDeleteShootInstanceFailed = response => ({
  type: types.API_DELETE_SHOOT_INSTANCE_FAILED,
  response,
});

export const apiGetAllShootInstancesLiveStatusRequest = (token, instancesArr) => ({
  type: types.API_GET_ALL_SHOOT_INSTANCES_LIVE_STATUS_REQUEST,
  token,
  instancesArr, // Array of arrays: instance region, value: instance's air_id (instance aws id)
});
export const apiGetAllShootInstancesLiveStatusSuccess = response => ({
  type: types.API_GET_ALL_SHOOT_INSTANCES_LIVE_STATUS_SUCCESS,
  response,
});
export const apiGetAllShootInstancesLiveStatusFailed = error => ({
  type: types.API_GET_ALL_SHOOT_INSTANCES_LIVE_STATUS_FAILED,
  error: {
    message: error.response?.data?.error,
    status: error.response?.status,
  },
});

export const apiGetShootInstanceLiveStatusRequest = (token, instanceAirId, instanceRegion) => ({
  type: types.API_GET_SHOOT_INSTANCE_LIVE_STATUS_REQUEST,
  token,
  instanceAirId,
  instanceRegion,
});
export const apiGetShootInstanceLiveStatusSuccess = response => ({
  type: types.API_GET_SHOOT_INSTANCE_LIVE_STATUS_SUCCESS,
  response,
});
export const apiGetShootInstanceLiveStatusFailed = error => ({
  type: types.API_GET_SHOOT_INSTANCE_LIVE_STATUS_FAILED,
  error: {
    message: error.response?.data?.error,
    status: error.response?.status,
  },
});

export const apiStartShootInstanceRequest = (token, instanceData) => ({
  type: types.API_START_SHOOT_INSTANCE_REQUEST,
  token,
  instanceData,
});
export const apiStartShootInstanceSuccess = response => ({
  type: types.API_START_SHOOT_INSTANCE_SUCCESS,
  response,
});
export const apiStartShootInstanceFailed = error => ({
  type: types.API_START_SHOOT_INSTANCE_FAILED,
  error: {
    message: error.response?.data?.error, // The "response" is from Axios error object
    status: error.response?.status,
  },
});

export const apiStopShootInstanceRequest = (token, instanceData) => ({
  type: types.API_STOP_SHOOT_INSTANCE_REQUEST,
  token,
  instanceData,
});
export const apiStopShootInstanceSuccess = response => ({
  type: types.API_STOP_SHOOT_INSTANCE_SUCCESS,
  response,
});
export const apiStopShootInstanceFailed = error => ({
  type: types.API_STOP_SHOOT_INSTANCE_FAILED,
  error: {
    message: error.response?.data?.error, // The "response" is from Axios error object
    status: error.response?.status,
  },
});

// DEPRECATED SECTION
// export const apiGetInstanceDetailRequest = (air_ids, token) => ({
//   type: types.API_GET_INSTANCE_DETAILS_REQUEST,
//   air_ids,
//   token,
// });
// export const apiGetInstanceDetailSuccess = response => ({
//   type: types.API_GET_INSTANCE_DETAILS_SUCCESS,
//   response,
// });
// export const apiGetInstanceDetailFailed = response => ({
//   type: types.API_GET_INSTANCE_DETAILS_FAILED,
//   response,
// });
// export const apiGearPostRequest = (
//   data,
//   token,
//   formType,
//   organisationId = null,
// ) => ({
//   type: types.API_GEAR_POST_REQUEST,
//   data,
//   token,
//   formType,
//   organisationId,
// });
// export const apiGearPostSuccess = response => ({
//   type: types.API_GEAR_POST_SUCCESS,
//   response,
// });
// export const apiGearPostFailed = response => ({
//   type: types.API_GEAR_POST_FAILED,
//   response,
// });
// export const resetGear = () => ({
//   type: types.RESET_GEAR,
// });
// export const apiGearGlobalListRequest = (token, organisationId) => ({
//   type: types.API_GEAR_GLOBAL_LIST_REQUEST,
//   token,
//   organisationId,
// });
// export const apiGearGlobalListSuccess = response => ({
//   type: types.API_GEAR_GLOBAL_LIST_SUCCESS,
//   response,
// });
// export const apiGearGlobalListFailed = response => ({
//   type: types.API_GEAR_GLOBAL_LIST_FAILED,
//   response,
// });
// export const apiGearAssignedListRequest = (
//   token,
//   project_id,
//   organisationId,
// ) => ({
//   type: types.API_GEAR_ASSIGN_LIST_REQUEST,
//   token,
//   project_id,
//   organisationId,
// });
// export const apiGearAssignedListSuccess = response => ({
//   type: types.API_GEAR_ASSIGN_LIST_SUCCESS,
//   response,
// });
// export const apiGearAssignedListFailed = response => ({
//   type: types.API_GEAR_ASSIGN_LIST_FAILED,
//   response,
// });
// export const apiGearAssignedRequest = (
//   token,
//   project_id,
//   data,
//   organisationId,
// ) => ({
//   type: types.API_GEAR_ASSIGN_REQUEST,
//   token,
//   project_id,
//   data,
//   organisationId,
// });
// export const apiGearAssignedSuccess = response => ({
//   type: types.API_GEAR_ASSIGN_SUCCESS,
//   response,
// });
// export const apiGearAssignedFailed = response => ({
//   type: types.API_GEAR_ASSIGN_FAILED,
//   response,
// });
// export const apiCameraPatchRequest = (
//   token,
//   gear_id,
//   gear,
//   project_id = null,
//   organisationId = null,
// ) => ({
//   type: types.API_CAMERA_PATCH_REQUEST,
//   token,
//   gear_id,
//   gear,
//   project_id,
//   organisationId,
// });
// export const apiCameraPatchSuccess = response => ({
//   type: types.API_CAMERA_PATCH_SUCCESS,
//   response,
// });
// export const apiCameraPatchFailed = response => ({
//   type: types.API_CAMERA_PATCH_FAILED,
//   response,
// });
// export const apiInstancePatchRequest = (
//   token,
//   gear_id,
//   gear,
//   project_id = null,
//   isDelete = false,
//   organisationId = null,
// ) => ({
//   type: types.API_INSTANCE_PATCH_REQUEST,
//   token,
//   gear_id,
//   gear,
//   project_id,
//   isDelete,
//   organisationId,
// });
// export const apiInstancePatchSuccess = response => ({
//   type: types.API_INSTANCE_PATCH_SUCCESS,
//   response,
// });
// export const apiInstancePatchFailed = response => ({
//   type: types.API_INSTANCE_PATCH_FAILED,
//   response,
// });
// export const apiInstanceDeleteSuccess = response => ({
//   type: types.API_INSTANCE_DELETE_SUCCESS,
//   response,
// });
// export const apiGearListRequest = token => ({
//   type: types.API_GEAR_LIST_REQUEST,
//   token,
// });
// export const apiGearListSuccess = response => ({
//   type: types.API_GEAR_LIST_SUCCESS,
//   response,
// });
// export const apiGearListFailed = response => ({
//   type: types.API_GEAR_LIST_FAILED,
//   response,
// });
// export const apiGetCameraDetailRequest = (air_ids, token) => ({
//   type: types.API_GET_CAMERA_DETAILS_REQUEST,
//   air_ids,
//   token,
// });
// export const apiGetCameraDetailSuccess = response => ({
//   type: types.API_GET_CAMERA_DETAILS_SUCCESS,
//   response,
// });
// export const apiGetCameraDetailFailed = response => ({
//   type: types.API_GET_CAMERA_DETAILS_FAILED,
//   response,
// });
// export const apiGetCameraDeactivateRequest = (
//   air_ids,
//   token,
//   project_id = null,
//   organisationId = null,
// ) => ({
//   type: types.API_POST_CAMERA_DEACTIVATE_REQUEST,
//   air_ids,
//   token,
//   project_id,
//   organisationId,
// });
// export const apiGetCameraDeactivateSuccess = response => ({
//   type: types.API_POST_CAMERA_DEACTIVATE_SUCCESS,
//   response,
// });
// export const apiGetCameraDeactivateFailed = response => ({
//   type: types.API_POST_CAMERA_DEACTIVATE_FAILED,
//   response,
// });
// END